import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';
import TestimonialsSection from '../components/section/Testimonials';
import ContactSection from '../components/section/Contact';
import ArticleCard from '../components/card/Article';

const PressPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/presse-mousse-gava.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      articles: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/articles/" },
          frontmatter: { home: { eq: true }, active: { eq: true } }
        },
        sort: {fields: frontmatter___date, order: DESC}
      )
      {
        nodes {
          frontmatter {
            title
            description
            publisher
            type
            link
            image {
              card {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              credit {
                text
                link
              }
            }
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
          body
        }
        totalCount
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`Presse | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade et isolation intérieure à Roanne`}
      pageDescription={`Découvrez tous les articles sur ${query.site.siteMetadata.title}. Contactez-nous si vous souhaitez écrire un article ou avez besoin de plus d'informations.`}
      pageKeywords="moussé gava, roanne, coteau, loire, presse, article, le ptit roannais, le bruit qui court"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Presse</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Presse</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {query.articles && query.articles.nodes.length ?
        <div className="wpo-article-area section-padding">
          <div className="container">
            <div className="row align-items-center">
              {query.articles.nodes.map(article => (
                <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-lg-4 col-md-6 col-12" />
              ))}
            </div>
          </div>
        </div>
      : null}
      <TestimonialsSection site={query.site} sectionStyle="1" />
      <ContactSection site={query.site} sectionStyle="2" />
    </Layout>
  )
}

export default PressPage
